<template>
  <div class="flix-tableau flix-flex">
    <div
      class="flix-flex flix-flex-center flix-gap-5 flix-tableau-titel"
      style="margin-bottom: 0"
    >
      <a
        href="#"
        class="flix-html-a"
        @click.prevent="$store.commit('tableau/setView', 'week')"
      >
        {{ $t("dates.weekdays")[$store.state.business.weekday] }}
      </a>
    </div>
    <div class="flix-tableau-container flix-tableau flix-flex flix-gap-10">
      <preview
        class="flix-flex flix-gap-5 flix-tableau-preview"
        style="flex: 1"
        :weekday="$store.state.business.weekday"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    preview() {
      return import("./preview");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>
